













































































































































































































































































































































import { defineComponent, ref, watch } from '@vue/composition-api'
import LinkList from '@/components/Menu.vue'
import BigContactCTA from '@/components/menu/BigContactCTA.vue'
import Lang from '@/components/menu/Lang.vue'
import NewsletterForm from '@/components/menu/NewsletterForm.vue'
import Socials from '@/components/menu/Socials.vue'
import { motionOK } from '@/inc/utils/motionOK'
import gsap from 'gsap'
import LineSplitter from '@/components/animation/LineSplitter.vue'
import { useRouter, useGetters } from '@u3u/vue-hooks'

export default defineComponent({
  name: 'mega-menu',
  props: ['content', 'opened'],
  components: {
    LinkList,
    BigContactCTA,
    Lang,
    NewsletterForm,
    Socials,
    LineSplitter,
  },
  setup(_props, ctx) {
    const megaMenuBGRedRef = ref()
    const megaMenuBGRef = ref()
    const primaryLinksRef = ref()
    const megamenuMaskRef = ref()
    const contactCTARef = ref()
    const router = useRouter()

    const { currentLang } = useGetters(['currentLang', 'chrome'])

    let TLIn: gsap.core.Timeline | undefined = undefined
    let TLOut: gsap.core.Timeline | undefined = undefined

    // Reinit timelines upon lang changes because LineSplitter components are not reactive
    // and still holds previous lang content
    watch(currentLang, () => {
      TLIn = undefined
      TLOut = undefined
    })

    const animateIn = (el, done) => {
      if (megamenuMaskRef.value) {
        megamenuMaskRef.value.scrollTo(0, 0)
      }

      if (motionOK()) {
        if (TLOut && TLOut.isActive()) {
          TLOut.kill()
        }

        if (TLIn === undefined) {
          TLIn = gsap
            .timeline({
              defaults: {
                ease: 'power4.out',
              },
            })
            .fromTo(
              [megaMenuBGRedRef.value, megaMenuBGRef.value],
              {
                scaleY: 0,
              },
              {
                scaleY: 1,
                duration: 1,
                stagger: 0.1,
                onComplete: () => {
                  // Fix fixed background positioning issue on some iOS version
                  // See : https://git.epic.net/bemac/website-v1/track/-/issues/176
                  megaMenuBGRef.value.style.transform = 'translate3d(0,0,0)'
                },
              },
              'start'
            )
            .fromTo(
              megamenuMaskRef.value,
              {
                clipPath: 'inset(0% 0% 100% 0%)',
              },
              {
                clipPath: 'inset(0% 0% 0% 0%)',
                duration: 1,
              },
              'start'
            )
            .addLabel('primary', 'start+=0.25')

          if (primaryLinksRef.value && primaryLinksRef.value.length > 0) {
            primaryLinksRef.value.forEach((el, i) => {
              TLIn!.add(el.getTween(), `primary+=${0.05 * i}`)
            })
          }

          TLIn.addLabel('secondary', 'primary+=0.1')
            .add(contactCTARef.value.getTween(), 'secondary')
            .fromTo(
              el.querySelectorAll('[data-megamenu-animate]'),
              {
                opacity: 0,
                y: -20,
              },
              {
                opacity: 1,
                y: 0,
                duration: 1,
                stagger: 0.1,
              },
              'secondary'
            )
            .then(done)
        } else {
          TLIn.restart().then(done)
        }
      } else {
        done()
      }
    }

    const animateOut = (el, done) => {
      if (motionOK() && !document.body.classList.contains('is-transitioning')) {
        if (TLIn && TLIn.isActive()) {
          TLIn.kill()
        }

        TLOut = gsap.timeline({
          defaults: {
            ease: 'power4.out',
          },
        })

        if (primaryLinksRef.value && primaryLinksRef.value.length > 0) {
          primaryLinksRef.value.forEach(el => {
            TLOut!.add(el.getTweenOut(), 'start')
          })
        }

        TLOut.to(
          el.querySelectorAll('[data-megamenu-animate]'),
          {
            opacity: 0,
            y: -20,
          },
          'start'
        )
          .add(contactCTARef.value.getTweenOut(), 'start')
          .to(
            [megaMenuBGRef.value, megaMenuBGRedRef.value],
            {
              scaleY: 0,
              stagger: 0.1,
            },
            'start+=0.1'
          )
          .to(
            megamenuMaskRef.value,
            {
              clipPath: 'inset(0% 0% 100% 0%)',
            },
            'start+=0.1'
          )
          .then(done)
      } else {
        done()
      }
    }

    const onClickLink = route => {
      console.log(router.route.value.path)

      if (router.route.value.path.includes(route)) {
        ctx.emit('closeMegaMenu')
      }
    }

    return {
      animateIn,
      animateOut,
      megaMenuBGRedRef,
      megaMenuBGRef,
      primaryLinksRef,
      megamenuMaskRef,
      contactCTARef,
      onClickLink,
    }
  },
})
