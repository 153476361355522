var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"float-label"},[_c('input',{staticClass:"float-label__input",class:[
      _vm.inputClasses,
      {
        'has-value': _vm.value,
        pristine: _vm.pristine,
        novalidate: _vm.novalidate,
        light: _vm.light,
      } ],attrs:{"type":_vm.type,"name":_vm.name,"id":_vm.id || _vm.name,"autocomplete":_vm.autocomplete,"required":_vm.required},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)},"blur":function($event){_vm.untouched = false},"invalid":function($event){_vm.untouched = false}}}),_c('label',{staticClass:"float-label__label",attrs:{"for":_vm.id || _vm.name}},[_vm._v(_vm._s(_vm.label))])])}
var staticRenderFns = []

export { render, staticRenderFns }