import VueRouter from 'vue-router'
import { Store } from 'vuex'

import { langAvailable, langDefault } from '@/inc/app.config'
import { loadLanguageAsync, setI18nLanguage } from '@/inc/i18n'
import { fetch, logger } from '@/inc/utils'
import { Language } from '@/inc/types'
import { RootState } from '@/inc/store'
import { push } from '@/inc/utils/tracking'
import { ResourceSingle } from '@/inc/store/modules/resource'

export const guards = (router: VueRouter, store: Store<RootState>) => {
  // Log
  router.beforeEach((to, from, next) => {
    logger.trace('[guards] from', from.name, 'to', to.name)
    next()
  })

  // Debug mode
  router.beforeEach((to, from, next) => {
    if (
      router.app?.$debug &&
      (from.query.debug !== undefined || to.query.debug !== undefined)
    ) {
      router.app.$debug = true
    }

    if (to.query.debug === undefined && from.query.debug !== undefined) {
      const query = { ...to.query, debug: from.query.debug }

      next({
        name: to.name as string,
        path: to.path,
        hash: to.hash,
        query,
        params: to.params,
      })
    } else {
      next()
    }
  })

  // Manage languages
  if (langAvailable.length > 1) {
    router.beforeEach(async (to, from, next) => {
      const { lang: langCurrent } = from.params as { lang: Language }
      const lang = to.params ? (to.params.lang as Language) : langDefault

      const langHasChanged = langCurrent !== undefined && langCurrent !== lang
      const langNext = langAvailable.includes(lang) ? lang : langDefault

      if (langHasChanged) {
        store.dispatch('ui/navigationStart')
      }

      await loadLanguageAsync(langNext)
        .then(langLoaded => setI18nLanguage(langLoaded, router.$$type))
        .then(lang => {
          if (langHasChanged) {
            // REVIEW
            // store.commit('user/SET_USER', lang)

            return store.dispatch('fetchChrome', { lang })
          }

          return Promise.resolve()
        })
        .catch(logger.error)

      next()
    })
  }

  // Fetch content
  router.beforeEach(async (to, _from, next) => {
    const isSpaFallback =
      router.$$type === 'client' && document.body.dataset.rendering === 'SPA'
    // Check if fetch is needed:
    // - on SPA fallback
    // - on second rendering ($resource is defined), client side
    const needFetch =
      isSpaFallback ||
      (router.$resource !== undefined && router.$$type === 'client')

    if (!needFetch) {
      router.$resource = {} as ResourceSingle

      return next()
    }

    // Fetch page resource
    // resource is added through `router`
    try {
      await fetch(to, router, false)
    } catch (error) {
      console.error(error)
    }

    return next()
  })

  // GTM - Analytics
  // router.beforeEach((_to, _from, next) => {
  //   if (router.$$type === 'client') {
  //     // GTM push here
  //     // window.dataLayer?.push({})
  //   }

  //   next()
  // })

  // router.afterEach(() => {
  //   store.dispatch('ui/navigationEnd')
  // })

  // GTM - Analytics
  router.afterEach((to, from) => {
    store.dispatch('ui/navigationEnd')
    // Only client side, skip first display
    if (router.$$type === 'client' && from.name) {
      // GTM: page view
      const layer = {
        event: 'page_view',
        pageTitle: router.$resource.meta?.title || document.title,
        pageLocation: window.location.href,
        pagePath: to.fullPath,
      }
      push(layer)
    }
  })
}
