




















import { defineComponent, ref } from '@vue/composition-api'
import gsap from 'gsap'

const CURTAIN_EASE = 'power2'
const CURTAIN_DURATION_IN = 0.45
const CURTAIN_DURATION_OUT = 0.6

export default defineComponent({
  setup() {
    const curtainRef = ref()

    const animateOut = () => {
      if (curtainRef.value) {
        curtainRef.value.style.transformOrigin = '100% 0'
      }

      return gsap.to(curtainRef.value, {
        scaleX: 0,
        duration: CURTAIN_DURATION_OUT,
        ease: `${CURTAIN_EASE}.out`,
      })
    }

    const animateIn = () => {
      if (curtainRef.value) {
        curtainRef.value.style.transformOrigin = '0 0'
      }

      return gsap.to(curtainRef.value, {
        scaleX: 1,
        duration: CURTAIN_DURATION_IN,
        ease: `${CURTAIN_EASE}.out`,
      })
    }

    return {
      curtainRef,
      animateIn,
      animateOut,
    }
  },
})
