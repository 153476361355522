













import { defineComponent } from '@vue/composition-api'
export default defineComponent({
  props: {
    picture: {
      type: Object,
    },
    className: {
      type: String,
      default: '',
    },
    sets: {
      type: Array,
    },
    sizes: {
      type: String,
    },
  },
})
