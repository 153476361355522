import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { RootState } from '@/inc/store'

export interface OnboardingState {
  onboardingOK: boolean
}

function getDefaultState(): OnboardingState {
  return {
    onboardingOK: true,
  }
}

const state: OnboardingState = getDefaultState()
const getters: GetterTree<OnboardingState, RootState> = {
  onboardingOK: state => state.onboardingOK,
}
const mutations: MutationTree<OnboardingState> = {
  FLAG_ONBOARDING(state) {
    state.onboardingOK = false
  },
}
const actions: ActionTree<OnboardingState, RootState> = {
  flagOnboarding({ commit }) {
    commit('FLAG_ONBOARDING')
  },
}

const onboarding: Module<OnboardingState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}

export default onboarding
