import { render, staticRenderFns } from "./Lang.vue?vue&type=template&id=49a18610&scoped=true&"
import script from "./Lang.vue?vue&type=script&lang=ts&"
export * from "./Lang.vue?vue&type=script&lang=ts&"
import style0 from "./Lang.vue?vue&type=style&index=0&id=49a18610&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49a18610",
  null
  
)

export default component.exports