





















































































































































































































































































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import LinkList from '@/components/Menu.vue'
import Lang from '@/components/menu/Lang.vue'
import NewsletterForm from '@/components/menu/NewsletterForm.vue'
import Socials from '@/components/menu/Socials.vue'
import BigContactCTA from '@/components/menu/BigContactCTA.vue'

export default defineComponent({
  name: 'chrome-footer',
  components: {
    LinkList,
    Lang,
    NewsletterForm,
    Socials,
    BigContactCTA,
  },
  setup() {
    const { chrome } = useGetters(['chrome'])
    const showCookiePreferences = () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      window.__cookieBar__.edit()
    }

    return {
      showCookiePreferences,
      chrome,
    }
  },
})
