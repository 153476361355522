export default {
  install(Vue, options) {
    // Prevent errors during SSR
    // TODO: Check if there is already a method to find out if we are in an SSR context
    if (typeof window === 'undefined') {
      return
    }

    const enterViewportEvent = new Event('enterViewport')

    function enterViewportCallback(entries, observer) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          // COMMENTED OUT FOR NOW
          entry.target.dispatchEvent(enterViewportEvent)
          observer.unobserve(entry.target)
        }
      })
    }

    const observer = new IntersectionObserver(enterViewportCallback, options)

    Vue.directive('enter-viewport', {
      bind(el) {
        observer.observe(el)
      },
      unbind(el) {
        observer.unobserve(el)
      },
    })
  },
}
