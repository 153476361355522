import { Language } from '@/inc/types'

/**
 * Languages
 */
export const langDefault: Language = 'fr'
export const langAvailable = ['fr', 'nl'] as const

/**
 * URLs, segments, endpoints
 */
export const segments = {
  news: ['news', 'actualites', 'nieuws'],
  reference: ['references', 'referenties'],
  references: ['references', 'referenties'],
  solution: ['solutions-incendie', 'oplossingen'],
  satisfaction: [
    'rapport-de-prestation',
    'tevredenheidsonderzoek',
    'prestatierapport',
  ],
} as Record<string, string | string[]>
export const endpointDefault = 'pages'

// API URLs
export const getApiUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_API_PATH}`
export const getThumborUrl = () =>
  `${process.env.VUE_APP_API_DOMAIN}${process.env.VUE_APP_THUMBOR_PATH}`

/**
 * GTM IDs
 */
export const gtm = {
  production: 'GTM-T7V6VD9',
  staging: 'GTM-T7V6VD9',
}

/**
 * Options
 */
export const isUnderMaintenance = false
export const useCookiebar = true
export const favicon = {
  tabColor: '#ffffff',
  tileColor: '#ffffff',
  themeColor: '#ffffff',
}

export const lightThemedTemplates = ['contact', 'solutions', 'notfound']
