export default {
  inserted: (el, binding) => {
    const target = document.querySelector(binding.value)
    if (target) {
      el.addEventListener('click', () => {
        target.dispatchEvent(new Event('toggle'))
      })
    }
  },
}
