const { VUE_APP_RELEASE } = process.env
const shouldTrack =
  VUE_APP_RELEASE === 'staging' || VUE_APP_RELEASE === 'production'

declare global {
  interface Window {
    dataLayer: DataLayer[]
  }
}

// Interface DataLayer {
//   event: string
//   eventCategory: string
//   eventAction: string
//   eventLabel: string
//   eventValue?: number
// }

type LayerValue = string | boolean | number | (() => void) | undefined
export interface DataLayer {
  [key: string]: LayerValue
  eventCallback?: () => void
}

export const push = (dataLayer: DataLayer) => {
  if (shouldTrack) {
    window.dataLayer?.push(dataLayer)
  } else {
    console.log(`\n📈 dataLayer.push [${event}]`)
    console.table(dataLayer)
  }
}

export const pushForm = (
  status: 'start' | 'click' | 'error' | 'success',
  data?: FormData
) => {
  const layer: DataLayer = {
    formStatus: status,
  }
  switch (status) {
    case 'start':
    case 'click':
    case 'error':
      layer.event = 'form_log'
      push(layer)
      break
    case 'success':
      if (data) {
        // Fill your layer here!
        // layer.event = 'contact_form'
        // layer.formType = data.get('form_type') as string
      } else {
        layer.event = 'form_log'
      }
      push(layer)
      break
    default:
  }
}

export const formStart = (form: HTMLFormElement) => {
  const initFormStart = () => {
    const data = new FormData(form)
    form.removeEventListener('focus', checkFocus, true)
    pushForm('start', data)
  }
  const checkFocus = (e: Event) => {
    if (e.target && [...form.elements].includes(e.target as Element)) {
      initFormStart()
    }
  }
  form.addEventListener('focus', checkFocus, true)
}
