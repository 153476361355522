



















































































import { computed, defineComponent } from '@vue/composition-api'
import Spinner from '@/components/Spinner.vue'

export default defineComponent({
  name: 'curtain',
  props: {
    url: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
      default: '',
    },
    target: {
      required: false,
      type: String,
    },
    type: {
      type: String,
      required: false,
      default: 'router-link',
    },
    number: {
      required: false,
      default: null,
    },
    submit: {
      required: false,
      default: false,
      type: Boolean,
    },
    loading: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  components: { Spinner },
  setup(props) {
    const isExternalLink = computed(
      () => props.target && props.target === '_blank'
    )

    const getComponentName = () =>
      isExternalLink.value || (props.url && props.url.startsWith('mailto:'))
        ? 'a'
        : props.type

    // In case of internal link (target == _self), convert given URL from absolute to relative
    // by stripping protocol + // + host
    const getURL = () => {
      if (typeof window !== 'undefined' && props.url && !isExternalLink.value) {
        const parser = document.createElement('a')
        parser.href = props.url

        return props.url.replace(`${parser.protocol}//${parser.host}`, '')
      }

      return props.url
    }

    return {
      getComponentName,
      getURL,
    }
  },
})
