









































































/* eslint-disable quote-props */
import { defineComponent, onMounted, ref } from '@vue/composition-api'
import gsap from 'gsap'
import { motionOK } from '@/inc/utils/motionOK'

export default defineComponent({
  name: 'big-contact-cta',
  props: {
    content: {
      required: true,
      type: Object,
    },
    animate: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const rootRef = ref()
    let TLIn: gsap.core.Tween | undefined = undefined
    let TLOut: gsap.core.Tween | undefined = undefined

    onMounted(() => {
      if (motionOK() && props.animate) {
        gsap.set(rootRef.value, {
          opacity: 0,
          y: -20,
          '--progress': 0,
        })
      }
    })

    const getTween = () => {
      if (TLIn === undefined) {
        TLIn = gsap.fromTo(
          rootRef.value.$el,
          {
            opacity: 0,
            y: -20,
            '--progress': 0,
          },
          {
            opacity: 1,
            y: 0,
            '--progress': 1,
          }
        )
      }

      return TLIn
    }

    const getTweenOut = () => {
      if (TLOut === undefined) {
        TLOut = gsap.to(rootRef.value.$el, {
          opacity: 0,
          y: -20,
          ease: 'power4.out',
        })
      }

      return TLOut
    }

    return {
      rootRef,
      getTween,
      getTweenOut,
    }
  },
})
