var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{ref:"root",staticClass:"header",class:{
    light: _vm.lightTheme,
    'megamenu-opened': _vm.megaMenuOpened,
    pristine: _vm.isPristine,
    hidden: _vm.isHidden,
    sticky: _vm.isSticky,
  },attrs:{"id":"js-header"}},[(
      _vm.chrome.highlight &&
      !(Array.isArray(_vm.chrome.highlight) && _vm.chrome.highlight.length === 0)
    )?_c('AlertBanner',{attrs:{"content":_vm.chrome.highlight}}):_vm._e(),_c('MegaMenu',{attrs:{"content":_vm.chrome,"opened":_vm.megaMenuOpened},on:{"closeMegaMenu":_vm.toggleMegaMenu}}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xxs-20 col-xxs-offset-2 header__content"},[_c('router-link',{staticClass:"logo",class:{
          hidden: _vm.isHome && !_vm.isAboveThreshold && !_vm.megaMenuOpened,
          'is-home': _vm.isHome,
          'is-below-threshold': !_vm.isAboveThreshold,
        },attrs:{"to":{ name: 'Home' }}},[_c('svg',{staticClass:"svg",attrs:{"width":"230","height":"40","viewBox":"0 0 892 155","version":"1.1","xmlns":"http://www.w3.org/2000/svg","xmlns:xlink":"http://www.w3.org/1999/xlink","preserveAspectRatio":"xMinYMid meet"}},[_c('path',{staticClass:"flame",attrs:{"d":"M44 0L7.2 63.6c-17.5 30.4-2 71 33.4 91.4l36.7-63.6C94.8 61 79.3 20.4 44 0zM19 81.6L49 29.9c1.7 1.6 3 4 3.5 6.5.9 3.8.2 7.8-1.9 11.4L20.8 99.5c-4.4-4.3-5.3-11.8-1.7-18zm46.3-8.2l-29.9 51.8c-1.7-1.7-2.9-4-3.5-6.6-.8-3.8-.1-7.8 1.9-11.4l29.9-51.7c1.7 1.7 2.9 4 3.5 6.5.8 3.8.2 7.9-2 11.4z"}}),_c('path',{staticClass:"text",attrs:{"d":"M284.1 103.2c0 18.6-11 32.2-36 32.2h-101V25h97.2c25.1 0 32.8 12.8 32.8 29 0 7.7-3.1 15.3-8.7 21.4 9.6 6 15.7 15 15.7 27.8zM175.6 68.4h58.7c7.5 0 14.5 0 14.5-11.5 0-11.4-7-11.4-14.7-11.4h-58.6v22.9zm80 33.4c0-11.7-6.8-11.7-14.4-11.7h-65.6v23.4h65.6c7.6 0 14.5 0 14.5-11.7zM325.7 47.6V70H414v20.5h-88.3v22.4H414v22.5H297.4V25H414v22.6h-88.4zM599.6 135.4h-28.4V59.7l-46 75.7h-20.4l-45.9-75.7v75.7h-28.4V25h38.3l46.3 78 46.2-78h38.4v110.4zM716 111.9h-66l-11.8 23.5h-31.3L664 25h38.3l57.1 110.4H728l-11.8-23.5zm-10.5-21.1L683.1 46l-22.5 44.8h44.9zM863.6 93.5c-4.6 14-18.2 19.6-39.6 19.6-29.4 0-42-10.4-42-33s12.5-32.9 42-32.9c21.8 0 35.2 5.6 39.8 19.7L892 59c-7.4-23.4-29.5-35.6-68-35.6-49.7 0-71 20.1-71 56.7 0 36.8 21.3 57 71 57 38.3 0 60.4-12.3 67.9-35.7l-28.3-8z"}})])]),(_vm.chrome.quote && _vm.chrome.quote.title && _vm.chrome.quote.url)?_c('Curtain',{staticClass:"quote",class:{
          'button--has-curtain--dark': _vm.lightTheme,
          'button--has-curtain--light': !_vm.lightTheme,
        },attrs:{"label":_vm.chrome.quote.title,"url":_vm.chrome.quote.url,"data-header-element":""}}):_vm._e(),_c('button',{staticClass:"header__burger",attrs:{"data-header-element":""},on:{"click":_vm.toggleMegaMenu}},[_c('div',{staticClass:"header__burger__line"}),_c('div',{staticClass:"header__burger__line"}),_c('div',{staticClass:"header__burger__line"})])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }