




























































































import { defineComponent, ref } from '@vue/composition-api'
import FloatingLabel from '@/components/FloatingLabel.vue'
import Spinner from '@/components/Spinner.vue'

const CM_SCRIPT_URL =
  'https://js.createsend1.com/javascript/copypastesubscribeformlogic.js'

export default defineComponent({
  name: 'newsletter-form',
  components: {
    FloatingLabel,
    Spinner,
  },
  props: {
    content: {
      required: true,
      type: Object,
    },
    light: {
      required: false,
      type: Boolean,
      default: false,
    },
    // Since this component is used at different places simultaneously (header + footer)
    // Allow to specify a custom unique ID
    id: {
      required: false,
      type: String,
      default: '',
    },
  },
  setup() {
    const email = ref('')
    const formRef = ref()
    const loading = ref(false)

    const onSubmit = () => {
      loading.value = true

      // Check if CampaignMonitor script has already been loaded
      const shouldInjectScript =
        document.querySelector(`script[src="${CM_SCRIPT_URL}"]`) === null

      // If it already has, do nothing, the script will attach submit event to the form
      // if it hasn't, load it.
      if (shouldInjectScript) {
        injectScript()
      }
    }

    const injectScript = () => {
      const script = document.createElement('script')
      script.onload = onCMScriptLoaded
      script.src = CM_SCRIPT_URL
      document.head.appendChild(script)
    }

    // Once the CM script has been loaded, manually trigger the submit event on the form
    // this will ben intercepted by CM's JS and form should submit
    const onCMScriptLoaded = () => {
      const submitEvent = new CustomEvent('submit', {
        bubbles: true,
        cancelable: true,
      })

      formRef.value.dispatchEvent(submitEvent)
    }

    return {
      email,
      onSubmit,
      loading,
      formRef,
    }
  },
})
