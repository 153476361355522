










































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import WebP from '@/components/WebP.vue'
import Curtain from '@/components/Curtain.vue'

export default defineComponent({
  name: 'not-found',
  components: { WebP, Curtain },
  props: {
    customContent: Object,
    default: null,
  },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
    }
  },
})
