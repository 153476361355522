

































































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'icon-link',
  props: {
    url: {
      required: false,
      type: String,
    },
    label: {
      required: false,
      type: String,
    },
    target: {
      required: false,
      type: String,
    },
  },
})
