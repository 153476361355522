














import { defineComponent, computed } from '@vue/composition-api'

const defaultMenuClassName = 'menu'
const defaultItemClassName = 'menu__item'

export default defineComponent({
  props: {
    menu: {
      required: true,
      type: Array,
    },
    classes: {
      required: false,
      type: String,
      default: '',
    },
    modifiers: {
      required: false,
      type: Array,
    },
    hasCurtains: {
      required: false,
      type: Boolean,
      default: false,
    },
    megamenuAnimate: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const menuClassName = computed(() => {
      const modifiedClassName =
        props.modifiers && props.modifiers.length > 0
          ? `${defaultMenuClassName}--${props.modifiers.join('--')}`
          : defaultMenuClassName

      return [modifiedClassName, props.classes]
    })

    const itemClassName = defaultItemClassName

    return {
      menuClassName,
      itemClassName,
    }
  },
})
