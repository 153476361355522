























































































































import { defineComponent, ref, computed } from '@vue/composition-api'
import stringHash from 'string-hash'
import gsap from 'gsap'

export default defineComponent({
  name: 'banner',
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const banner = ref()

    const hash = computed(() => {
      // Generate an ID combining banner's HTML content and link's URL
      // It will be different everytime the banner content changes, so the user
      // will see the new banner.
      const uniqID = `${props.content.htmltext}-${
        props.content.link && props.content.link.url
          ? props.content.link.url
          : ''
      }`

      return stringHash(uniqID)
    })

    const isUnread = ref(false)
    if (typeof window !== 'undefined') {
      isUnread.value =
        localStorage.getItem(hash.value) === null && Boolean(props.content)
    }

    const dismiss = () => {
      // Using our banner's unique hash, use localstorage to set a flag specifying that banner should not be shown
      // Value does not actually matter, we'll just check if the key is defined
      localStorage.setItem(hash.value, hash.value)

      // @TODO: Fancier animation. IE: Stagger out content, then shrink banner...
      // Animate banner out
      gsap.to(banner.value, {
        height: 0,
        duration: 0.25,
        ease: 'power2.out',
      })
    }

    return {
      banner,
      dismiss,
      isUnread,
    }
  },
})
