import Vue from 'vue'
import Vuex, { Plugin, Store, StoreOptions } from 'vuex'
// eslint-disable-next-line @typescript-eslint/no-unused-vars

// Modules
import { chrome, ChromeState } from './modules/chrome'
import { resource, ResourceState } from './modules/resource'
import ui, { UIState } from './modules/ui'
import onboarding, { OnboardingState } from './modules/onboarding'
import { version, VersionState } from './modules/version'
// End modules

/**
 * Store
 */
export interface RootState {
  chrome: ChromeState
  resource: ResourceState
  version: VersionState
  // Namespaced
  ui: UIState
  onboarding: OnboardingState
}

Vue.use(Vuex)

export default function createStore(): Store<RootState> {
  const plugins: Plugin<RootState>[] = []

  const store: StoreOptions<RootState> = {
    modules: {
      chrome,
      resource,
      version,
      // Namespaced
      ui,
      onboarding,
    },
    plugins,
  }

  return new Vuex.Store<RootState>(store)
}
