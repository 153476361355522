/* eslint-disable @typescript-eslint/no-explicit-any */
import { MetaInfo } from 'vue-meta'
import { ActionTree, Module, MutationTree, GetterTree } from 'vuex'
import { Languages } from '@/inc/types'
import { RootState } from '@/inc/store'

export interface Content {
  title: string
  components?: any[]
}

export interface Resource {
  meta?: any
  content: null | Content | Content[]
}

export interface ResourceSingle extends Resource {
  url: string
  type: string

  id?: number
  slug?: string
  languages?: Languages
  meta?: MetaInfo
  template?: string
  i18n?: Record<string, any>
}

export interface ResourceArchive extends Resource {
  pages: number
  currentPage: number
  perPage: number
  totalPosts: number
  isLastPage: boolean
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ResourceState extends ResourceSingle {}

const state: ResourceState = {
  content: null,
  slug: '',
  url: '',
  type: '',
  languages: {} as Languages,
  meta: {},
  template: '',
  i18n: {},
} as ResourceSingle

const getters: GetterTree<ResourceState, RootState> = {
  content: (state: ResourceState) => state.content,
  languages: (state: ResourceState) => state.languages,
  meta: (state: ResourceState) => state.meta,
  template: (state: ResourceState) => state.template,
}

const mutations: MutationTree<ResourceState> = {
  SET_RESOURCE(state, payload: ResourceSingle) {
    if (payload) {
      const { content, languages, meta, template, i18n, slug, url } = payload

      state.content = content
      // NOTE: `children` moved to content
      // state.children = children
      state.languages = languages
      state.meta = meta
      state.template = template
      state.i18n = i18n
      state.slug = slug
      state.url = url
    }
  },
}

const actions: ActionTree<ResourceState, RootState> = {}

export const resource: Module<ResourceState, RootState> = {
  state,
  getters,
  mutations,
  actions,
}
