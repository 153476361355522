



















































import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'socials',
  props: {
    socials: {
      required: true,
      type: Array,
    },
  },
})
