

































































































































































































































































































import {
  defineComponent,
  ref,
  computed,
  onBeforeUnmount,
  onMounted,
} from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { throttle, debounce } from 'throttle-debounce'

import { lightThemedTemplates } from '@/inc/app.config'
import MegaMenu from '@/components/MegaMenu.vue'
import AlertBanner from '@/components/AlertBanner.vue'

export default defineComponent({
  name: 'chrome-header',
  components: { MegaMenu, AlertBanner },
  setup(_props, ctx) {
    const { chrome } = useGetters(['chrome'])
    const { template } = useGetters(['template'])
    const megaMenuOpened = ref(false)

    const isPristine = ref(true)
    const isSticky = ref(false)
    const isHidden = ref(false)

    const lightTheme = computed(
      () =>
        ctx.root.$route.meta.lightHeaderTheme ||
        lightThemedTemplates.includes(template.value)
    )

    const isHome = computed(() => ctx.root.$route.name === 'Home')

    const toggleMegaMenu = () => {
      megaMenuOpened.value = !megaMenuOpened.value
      ctx.root.$emit(
        megaMenuOpened.value ? 'scroll-disabled' : 'scroll-enabled'
      )
    }

    const root = ref()
    let previousScrollPos = 0
    const headerHeight = 104
    let threshold = headerHeight // Under this threshold, ignore "sticky" behavior
    let resetHeaderTimerID = 0

    // Homepage is a specific case where we do not want to show back header
    // until user scrolls past a specifi threshold (the hero height)
    const computeThreshold = () => {
      if (isHome.value) {
        const hero = document.querySelector('.js-hero')
        if (hero) {
          threshold = hero.getBoundingClientRect().height
        }
      }
    }

    const hideWithoutTransition = () => {
      root.value.style.position = 'fixed'
      root.value.style.transitionDuration = '0ms'
      root.value.style.transform = 'translateY(-100%)'
    }

    const resetInlineStyles = () => {
      root.value.style.position = null
      root.value.style.transitionDuration = null
      root.value.style.transform = null
    }

    const isAboveThreshold = ref(false)

    const onScroll = throttle(100, () => {
      if (!megaMenuOpened.value) {
        const currentScrollPos = window.scrollY || window.pageYOffset
        const scrollingDown = currentScrollPos > previousScrollPos

        if (currentScrollPos <= 0) {
          // We have scrolled up to the very top
          // We want to reset the header to its original styes and position
          isHidden.value = false
          isPristine.value = true
          isSticky.value = false
        }

        isAboveThreshold.value = currentScrollPos >= threshold

        if (scrollingDown && isAboveThreshold.value) {
          // We have scrolled past the header OR the threshold
          if (isPristine.value) {
            // If header is still pristine, hide it without transition
            hideWithoutTransition()
            isPristine.value = false
            isHidden.value = true

            resetHeaderTimerID = window.setTimeout(() => {
              resetInlineStyles()
            }, 200)
          } else {
            // We are scrolling down, scroll position is higher than (or equals) threshold
            // We want to animate OUT the header
            isHidden.value = true
            isSticky.value = false
            isPristine.value = false
          }
        }

        if (!scrollingDown) {
          if (isAboveThreshold.value) {
            // We are scrolling up, scroll position is higher than (or equals) threshold
            // We want to animate IN the header
            resetInlineStyles()
            isSticky.value = true
            isHidden.value = false
            isPristine.value = false
          } else if (!isPristine.value && threshold > headerHeight) {
            // We are scrolling up, scroll position is lower than threshold
            // We want to animate OUT the header, then reset it to its original position
            isHidden.value = true
            isPristine.value = false
            resetHeaderTimerID = window.setTimeout(() => {
              resetInlineStyles()
              isHidden.value = false
              isPristine.value = true
            }, 200)
          }
        }

        previousScrollPos = currentScrollPos
      }
    })

    const onResize = debounce(250, () => {
      computeThreshold()
    })

    onMounted(() => {
      computeThreshold()
      window.addEventListener('scroll', onScroll)
      window.addEventListener('resize', onResize)
    })

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', onScroll)
      window.removeEventListener('resize', onResize)

      onScroll.cancel()
      onResize.cancel()

      window.clearTimeout(resetHeaderTimerID)
    })

    return {
      megaMenuOpened,
      toggleMegaMenu,
      root,
      chrome,
      lightTheme,
      isHome,
      isPristine,
      isSticky,
      isHidden,
      isAboveThreshold,
    }
  },
})
